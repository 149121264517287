import React, { Component, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
//dev component
import { PC_RECEIPT_GREETING } from '../../../common/constants';
import DropDown from '../../../components/DropDown';
import FileUploader from '../../../components/FileUploader';
import WorkingHours from '../../../components/v5/WorkingHours';
import BreakHours from '../../../components/v5/BreakHours';
import CloseReceipt from '../../../components/CloseReceipt';
import FeedbackSettings from '../../../components/FeedbackSettings';
import OpenExternalV5 from '../../../components/v5/ExternalSystem';
import BrightSettingV5 from '../../../components/v5/BrightSetting';
import ArtificialSettingV5 from '../../../components/v5/ArtificialSol';
import Ckeditor from '../../../components/v5/Ckeditor';
import AreaTableEdit from './tableEdit';
//FLUX actions and store
import AdminAction from '../../../flux/actions/adminActions';
import AdminStore from '../../../flux/stores/adminStore';
//feature
import Feature from '../../../components/v5/Feature';
import CFlag, { Hideable } from '../../../reactcomponents/CFlag';
import SwitchCheckbox from '../../../reactcomponents/SwitchCheckbox';
import { SelectInputRow, FormInputWithLabelRow } from '../../../reactcomponents/Form';
import { SingleSelectDD } from '../../../reactcomponents/Dropdown';
import {
	formatValueToNameObj
} from '../../../common/v5/helpers';
import { SaveButton, CancelButton, RightEndHelper as Helper, ListContentWrapper, ActionsWrapper, Edit, Remove, ACTION_DELETE } from '../../../reactcomponents/Admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SkillUpdateModal, AddAgentBtnStyle } from '../../v5/skills/Skills';
import { DeletePopup } from '../../../reactcomponents/Dialog';
import {
	CELL_ACTION,
	CELL_DATE_TIME,
	NormalizedTable,
	customPageTotal
} from '../../../reactcomponents/Table'
import { BTN_TXT_SAVE, BTN_TXT_SAVING } from '../../../common/v5/constants';
import { AlertWrapper } from '../../v5/admin';
import { DismissableAlert } from '../../../reactcomponents/Error';

global.AREA = 1, global.WORKINGHOUR = 2, global.CLOSERECEIPT = 3, global.OPENEXTERNAL = 4, global.FEEDBACK = 5, global.BRIGHT = 6, global.ARTIFICIALSOL = 7;
global.CONVERSATIONBOTWORKINGHOUR = 8, global.AREAROUTING = 9, global.AREAREPLACEMENT = 10, global.AREAAUTOFORWARD = 11, global.AREASMS = 12,
global.AREAFORWARDEXTERNAL = 13, global.AREASKILLS = 14,
global.BREAKHOURS = 15, global.CONVBOTBREAKHOURS=16; ;

const SkillTableOptions = {
	paginationSize: 3,
	pageStartIndex: 1,
	hideSizePerPage: true, // Hide the sizePerPage dropdown always
	hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: '<<',
	prePageText: '<',
	nextPageText: '>',
	lastPageText: '>>',
	showTotal: true,
	paginationTotalRenderer: customPageTotal,
	disablePageTitle: true,
	sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
};

const renderSkillActions = (
	cell
	, { id, skillId, proficiency }
	, { onDelete, onEdit }
) => {
	return (
		<ActionsWrapper>
			{onEdit && <Edit id={id} onClick={() => onEdit(id, skillId, proficiency)} />}
			{onDelete && <Remove
				id={id}
				onClick={(() => onDelete(id))}
			/>}
		</ActionsWrapper>
	)
}

export default class OrganisationEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: I("Add new area"),
			activeTab: AREA,
			features: {},
			area: {},
			user: {},
			saving: false,
			tabPage: 1,
			openSkillModal: false,
			activeSkillModal: {},
			activeAreaSkill: 0,
			showDeleteAlert: false,
			toBeDeleted: 0,
			copyTriggered: false,
			pasteEnabled: false,
			copyData: AdminStore.state.copyData,
			copyIconColor: '#6D6D6D',
			pasteIconColor: '#6D6D6D',
			copyHoverText: 'Copy',
        	pasteHoverText: 'Paste', 
		}
		this.handleCopyData = this.handleCopyData.bind(this);
		this.handlePasteData = this.handlePasteData.bind(this);
	}
	toggle = (tab) => {
		if (tab === AREASKILLS) {
			//fetching necessary skills stuff
			if(this.props.onLoadAreaSkillsTab) {
				this.props.onLoadAreaSkillsTab(this.props.id);
			}
		}
		if (this.state.activeTab === AREA && this.props.id <= 0) {
			return;
		}
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.title !== this.state.title) {
			if (this.state.title === "Add new area") {
				this.setState({ 
					copyData: {}, 
					area: {} 
				}, () => {
				});
			} else if (this.state.title === "Edit area") {
				if (this.state.copyData.data && Object.keys(this.state.copyData.data).length > 0 && JSON.stringify(this.state.area) !== JSON.stringify(this.state.copyData.data)) {
	
					this.setState({ 
						area: { ...this.state.copyData.data } 
					}, () => {
						console.log("State updated successfully with copied data.");
					});
				}
			}
		}

	}
	
	

	componentWillMount = () => {
		AdminStore.listen(this.onFluxStoreChange);
		let orgId = 0;
		if (typeof this.props.orgId !== "undefined") {
			orgId = this.props.orgId;
		}
		AdminAction.getAreaById(this.props.id, orgId);
	}


	handleCopyData = () => {
		if (this.state.title === "Edit area") {
			AdminAction.setCopyData(this.state.area);
			this.setState({ 
				copyTriggered: true,
				copyIconColor: '#0C87F7',
				copyHoverText: 'Copied'
			}, () => {
				console.log("Data copied:", this.state.copyData);
			});
		}
	}
	

	handlePasteData = () => {
		if (this.state.title === "Add new area") {
			this.setState({
				area: { ...this.state.copyData.data },  
				pasteEnabled: false,  
				pasteIconColor: '#0C87F7',
				pasteHoverText: 'Pasted'
			}, () => {
				console.log("Data pasted:", this.state.area);
			});
		}
	}
	
	
	
	
	onFluxStoreChange = (state) => {
		var states = state.areaConf;
		if (states.area) {
			this.setState({
				area: states.area,
				copyData: state.copyData || {}
			});
		}
	}
	componentWillUnmount = () => {
		AdminStore.unlisten(this.onFluxStoreChange);
	}
	componentDidMount = () => {
		if (this.props.id >= 1) {
			this.setState({ title: I("Edit area") });
		}
		AdminStore.listen(this.onFluxStoreChange);
	}
	handleSaveArea = (ActionId, values) => {
		this.setState({ saving: true });
		AdminAction.saveAdminArea(this.props.id, values)
			.then(function (data) {
				this.setState({ saving: false });
				this.props.onCloseEdit();
			}.bind(this),
				() => {
					this.props.onCloseEdit();
				});
	}
	handleSaveAreaExternal = (ActionId, values, areaId, orgId) => {
		this.setState({ saving: true });
		AdminAction.saveAdminArea(this.props.id, values)
			.then(function (data) {
				this.setState({ saving: false });
				AdminAction.getAreaById(areaId, orgId);
			}.bind(this));
	}
	handleCancelToOrg = () => {
		window.location.hash = "#organisations";
	}
	handleWorkingException = (values) => {
		AdminAction.saveAreaException(this.props.id, values)
			.then(() => {
				console.log("Done");
			});
	}
	handleChangeTab = (tab) => {
		let page = this.state.tabPage;
		if (tab) {
			page = tab;
		}
		this.setState({
			tabPage: page == 1 ? 2 : 1,
			activeTab: page == 1 ? AREAREPLACEMENT : AREA
		})
	}
	handleOpenSelectSkillModal = () => {
		this.setState({
			openSkillModal: true
		});
	}
	handleCloseSkillModal = () => {
		this.setState({
			openSkillModal: false,
			activeAreaSkill: 0,
			activeSkillModal: {
				skill: 0,
				proficiency: 0
			}
		});
	}
	handleSaveSkill = (skillId, proficiencyId) => {
		const params = {
			id: this.state.activeAreaSkill,
			areaId: this.props.id,
			skillId,
			proficiencyId
		}
		if(this.props.onSaveAreaSkill) {
			this.props.onSaveAreaSkill(params);
		}
		this.setState({
			activeSkillModal: {
				skill: 0,
				proficiency: 0
			},
			activeAreaSkill: 0
		});
	}
	handleEditAreaSkill = (id, skill, proficiency) => {
		this.setState({
			openSkillModal: true,
			activeAreaSkill: id,
			activeSkillModal: {
				skill: skill,
				proficiency: proficiency,
				id: id
			}});
	}
	handleDeleteAreaSkill = (id) => {
		this.setState({showDeleteAlert: true, toBeDeleted: id});
		if(this.state.openSkillModal) {
			this.handleCloseSkillModal();
		}
	}
	handleConfirmDelete = () => {
		this.props.onDeleteAreaSkill(this.state.toBeDeleted);
		this.setState({showDeleteAlert: false, toBeDeleted: 0});
	}
	handleCancelDelete = () => {
		this.setState({showDeleteAlert: false, toBeDeleted: 0});
	}
	render = () => {
		var conversationBotTabContent = null;
		var iconTimeStyle = {
			cursor: 'pointer',
			verticalAlign: 'sub'
		};
		var conversationBotTab = <li role="presentation" id="orgTab_5" className="nav-item" data-qa-id="QA_tab_working_bot">
			<a className={this.state.activeTab === CONVERSATIONBOTWORKINGHOUR ? "active" : ""} href="#library"
				onClick={() => this.toggle(CONVERSATIONBOTWORKINGHOUR)}>{I("Working hours for Conversation bot")}
			</a>
		</li>;
		var conversationBotBreakTab = <li role="presentation" id="orgTab_13" className="nav-item" data-qa-id="QA_tab_working_bot">
				<a className={this.state.activeTab === CONVBOTBREAKHOURS ? "active" : ""} href="#library"
					onClick={() => this.toggle(CONVBOTBREAKHOURS)}>{I("Working hours break for Conversation bot")}
				</a>
			</li>;
		var tabPage = this.state.tabPage;
		var element = null;

		const allSkills = this.props.skillList;
		const proficiencyList = this.props.proficiencyList;
		const areaSkills = this.props.areaSkills;
		const adminActionAlert = this.props.adminActionAlert;
		const rowEvents = {
			onClick: (e, row) => {
			}
		};
		const skillCols = [
			{
				dataField: 'id',
				text: 'ID',
				sort: true,
				hidden: true
			},
			{
				dataField: 'name',
				text: I('Skill'),
				sort: true,
			},
			{
				dataField: 'proficiency',
				text: I('Minimum required proficiency'),
				sort: true,
				formatter: (cell, row) => {
					let profName = "";
					proficiencyList.forEach((item) => {
						if (item.id === cell) {
							profName = item.name;
						}
					});
					return profName;
				},
			},
			{
				isDummyField: true,
				dataField: 'id',
				text: I('Action'),
				headerClasses: 'center',
				formatter: (cell, row) => {
					return renderSkillActions(cell, row, actionDatas);
				},
			}
		];
		const actionDatas = {
			onEdit: this.handleEditAreaSkill
			, onDelete: this.handleDeleteAreaSkill
		}

		if (this.props.id > 0) {
			element = <React.Fragment>
				<TabPane tabId={WORKINGHOUR}>
					<WorkingHours
						orgWorkingTimeRules={this.state.area.orgWorkingTimeRules}
						orgWorkingTimeExceptions={this.state.area.orgWorkingTimeExceptions}
						workingTimeRules={this.state.area.workingTimeRules}
						workingTimeExceptions={this.state.area.workingTimeExceptions}
						usedFor="area"
						saving={this.state.saving}
						saveOrganization={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
						saveWorkingHourExcp={this.handleWorkingException}
						updateRulesField={"workingTimeRules"}
						updateExceptionsField={"workingTimeExceptions"}
					/>
				</TabPane>
				<TabPane tabId={BREAKHOURS}>
					<BreakHours
						orgWorkingTimeRules={this.state.area.orgBreakTimeRules}
						breakTimeRules={this.state.area.breakTimeRules}
						usedFor="area"
						saving={this.state.saving}
						saveOrganization={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
						saveWorkingHourExcp={this.handleWorkingException}
						updateRulesField={"areaBreakTimeRules"}
					/>
				</TabPane>
				<TabPane tabId={CONVERSATIONBOTWORKINGHOUR}>
					<WorkingHours
						orgWorkingTimeRules={this.state.area.orgConversationBotWorkingTimeRules}
						orgWorkingTimeExceptions={this.state.area.orgConversationBotWorkingTimeExceptions}
						workingTimeRules={this.state.area.conversationBotWorkingTimeRules}
						workingTimeExceptions={this.state.area.conversationBotWorkingTimeExceptions}
						usedFor="area"
						saving={this.state.saving}
						saveOrganization={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
						saveWorkingHourExcp={this.handleWorkingException}
						updateRulesField={"conversationBotWorkingTimeRules"}
						updateExceptionsField={"conversationBotWorkingTimeExceptions"}
					/>
				</TabPane>
				<TabPane tabId={CONVBOTBREAKHOURS}>
					<BreakHours
						orgWorkingTimeRules={this.state.area.conversationBotOrgBreakTimeRules}
						breakTimeRules={this.state.area.conversationBotBreakTimeRules}
						usedFor="area"
						saving={this.state.saving}
						saveOrganization={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
						saveWorkingHourExcp={this.handleWorkingException}
						updateRulesField={"conversationBotBreakTimeRules"}
					/>
				</TabPane>
				<TabPane tabId={AREASKILLS}>
					{ cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") &&
						<Fragment>
							<form id="skillAdminForn" className="admin-one-form edit-admin-form skills-admin-form">
								<div className="row" style={{ width: '100%' }}>
									<div className="col-11 col-md-11">
										<FormInputWithLabelRow
											label={I('Required area skill')}
											inlineLabel={false}
											helperTxt={I('Manage area skills')}
										>
										</FormInputWithLabelRow>
									</div>
									<div className="col-1 col-md-1" style={{ display: 'flex', gap: '8px' }}>
										<div style={AddAgentBtnStyle} onClick={this.handleOpenSelectSkillModal}>
											<i style={{ color: '#6D6D6D' }} className="icon-add"></i>
										</div>
									</div>
								</div>
								<ListContentWrapper className={"v5-table-style"}>
									<BootstrapTable
										keyField="id"
										data={areaSkills}
										noDataIndication={I("No data to display")}
										columns={skillCols}
										bordered={false}
										condensed={false}
										defaultSorted={ [{
											dataField: 'name',
											order: 'asc'
										}]}
										pagination={paginationFactory(SkillTableOptions)}
										rowEvents={ rowEvents }
										hover
									/>
								</ListContentWrapper>
							</form>
						</Fragment>
					}
					{
						cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") && this.state.openSkillModal &&
						<SkillUpdateModal
							show={this.state.openSkillModal}
							isNew={this.state.activeAreaSkill ? false : true}
							selectAgentMode={false}
							selectSkillMode={true}
							selectedSkill={this.state.activeSkillModal}
							proficiencyList={proficiencyList}
							skillList={allSkills}
							onClose={this.handleCloseSkillModal}
							onSave={this.handleSaveSkill} onDelete={this.handleDeleteAreaSkill} />
					}
					{	cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") &&
						<DeletePopup
							title={I("Are you sure you want to remove this skill from area?")}
							icon={'icon-caution'}
							show={this.state.showDeleteAlert}
							onDelete={this.handleConfirmDelete}
							onClose={this.handleCancelDelete}
						/>
					}
				</TabPane>
				<TabPane tabId={CLOSERECEIPT}>
					<CloseReceipt
						id={this.props.id}
						features={this.state.features}
						closeReceiptRules={this.state.area.closeReciptRules}
						languages={this.state.area.languages}
						archiveImages={this.state.area.areaFileArchive}
						saveOrganization={this.handleSaveArea}
						usedFor="area"
						saving={this.state.saving}
						cancelToOrg={this.handleCancelToOrg}
						keepOrgCloseRcp={this.state.area.keepOrgCloseRcp}
					/>
				</TabPane>
				<TabPane tabId={OPENEXTERNAL}>
					{this.state.area.organisationId &&<OpenExternalV5
						id={this.props.id}
						orgId={this.state.area.organisationId}
						externalSystemRules={this.state.area.externalSystemRules}
						openExternalSystemRulesId={this.state.area.openExternalSystemRulesId}
						saveOrganization={this.handleSaveArea}
						saveAreaExternal={this.handleSaveAreaExternal}
						usedFor="area"
						saving={this.state.saving}
						cancelToOrg={this.props.onCloseEdit}
						keepOrgOpenExt={this.state.area.keepOrgOpenExt}
					/>}
				</TabPane>
				<TabPane tabId={FEEDBACK}>
					<FeedbackSettings
						id={this.props.id}
						features={this.state.features}
						feedback={this.state.area.feedback}
						languages={this.state.area.languages}
						archiveImages={this.state.area.areaFileArchive}
						saveOrganization={this.handleSaveArea}
						usedFor="area"
						saving={this.state.saving}
						cancelToOrg={this.handleCancelToOrg}
					/>
				</TabPane>
				<TabPane tabId={BRIGHT}>
				{this.state.area.brightSettings &&<BrightSettingV5
						id={this.props.id}
						brightSettings={this.state.area.brightSettings}
						saveOrganization={this.handleSaveArea}
						usedFor="area"
						saving={this.state.saving}
						cancelToOrg={this.props.onCloseEdit}
					/>}
				</TabPane>
				<TabPane tabId={ARTIFICIALSOL}>
					{this.state.area.artificialSettings && <ArtificialSettingV5
						id={this.props.id}
						artificialSettings={this.state.area.artificialSettings}
						saveOrganization={this.handleSaveArea}
						usedFor="area"
						saving={this.state.saving}
						cancelToOrg={this.props.onCloseEdit}
					/>}
				</TabPane>
				<TabPane tabId={AREAREPLACEMENT}>
					<AreaTableEdit
						type={AREAREPLACEMENT}
						id={this.props.id}
						saveOrg={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
					/>
				</TabPane>
				<TabPane tabId={AREAAUTOFORWARD}>
					<AreaTableEdit
						type={AREAAUTOFORWARD}
						id={this.props.id}
						saveOrg={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
					/>
				</TabPane>
				<TabPane tabId={AREASMS}>
					<AreaTableEdit
						type={AREASMS}
						id={this.props.id}
						saveOrg={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
					/>
				</TabPane>
				<TabPane tabId={AREAFORWARDEXTERNAL}>
					<AreaTableEdit
						type={AREAFORWARDEXTERNAL}
						id={this.props.id}
						saveOrg={this.handleSaveArea}
						cancelToOrg={this.props.onCloseEdit}
					/>
				</TabPane>
			</React.Fragment>
		} else {
			element = null;
		}

		return (
			<div className="organization-edit" id="area-edit-v5">
				<div className="panel panel-default">
					<div className="row">
						<div className="col-lg-8 panel-heading">
							<h3>
								<span className='edit-header-icon'>
									<i
										className='icon-edit'
									/>
								</span>
								{this.state.title}
							</h3>
						</div>
						<div className='col-lg-4' style={{ textAlign: 'right', right: 10 }}>
						{this.state.title === "Edit area" && (
                            <span 
                                style={iconTimeStyle} 
                                onClick={this.handleCopyData} 
                                title={this.state.copyHoverText}
                            >
                                <i style={{ color: this.state.copyIconColor, marginRight: 10}} className="icon-copy"></i>
                            </span>
                        )}

                        {this.state.title === "Add new area" && this.state.copyData && this.state.copyData.data && (
                            <span 
                                style={iconTimeStyle} 
                                onClick={this.handlePasteData} 
                                title={this.state.pasteHoverText}
                            >
                                <i style={{ color: this.state.pasteIconColor, marginRight: 10}} className="icon-paste"></i>
                            </span>
                        )}
							<span style={iconTimeStyle} onClick={this.props.onCloseEdit}>
								<i className='icon-times'></i>
							</span>
						</div>
						<AlertWrapper id={"OrgAreaAlert"} hidden={adminActionAlert.show ? false : true} onDismiss={this.props.onDismissAlert}>
							<DismissableAlert show={adminActionAlert.show ? true : false}
								role={adminActionAlert.type === "error" ? "danger" : "success"}
								text={adminActionAlert.msg}
								icon={adminActionAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
								onDismiss={this.props.onDismissAlert}
							/>
						</AlertWrapper>
					</div>
					<div className="panel-body">
						{tabPage == 1 &&
							<ul className="nav org-nav-tabs col-md-12">
								<li role="presentation" id="orgTab_0" className="nav-item" data-qa-id="QA_org">
									<a className={this.state.activeTab === ORG ? "active" : ""} href="#"
										onClick={() => this.toggle(ORG)}>{I('Organization')}
									</a>
								</li>
								<li role="presentation" id="orgTab_1" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === WORKINGHOUR ? "active" : ""} href="#"
										onClick={() => this.toggle(WORKINGHOUR)}>{I('Working hours')}
									</a>
								</li>
								<li role="presentation" id="orgTab_12" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === BREAKHOURS ? "active" : ""} href="#"
										onClick={() => this.toggle(BREAKHOURS)}>{I('Working hours break')}
									</a>
								</li>
								<Feature tag="open-external-system">
									<li role="presentation" id="orgTab_2" className="nav-item" data-qa-id="QA_org_external">
										<a className={this.state.activeTab === OPENEXTERNAL ? "active" : ""} href="#"
											onClick={() => this.toggle(OPENEXTERNAL)}>{I('Open External System settings')}
										</a>
									</li>
								</Feature>
								<Feature tag="bright-integration">
									<li role="presentation" id="orgTab_3" className="nav-item" data-qa-id="QA_org_bright">
										<a className={this.state.activeTab === BRIGHT ? "active" : ""} href="#"
											onClick={() => this.toggle(BRIGHT)}>{I('Bright Settings')}
										</a>
									</li>
								</Feature>
								<Feature tag="artificial-solutions">
									<li role="presentation" id="orgTab_4" className="nav-item" data-qa-id="QA_org_artificial">
										<a className={this.state.activeTab === ARTIFICIALSOL ? "active" : ""} href="#"
											onClick={() => this.toggle(ARTIFICIALSOL)}>{I('Artificial Solutions Settings')}
										</a>
									</li>
								</Feature>
								{conversationBotTab}
								{conversationBotBreakTab}
								{
									cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") &&
									<li role="presentation" id={"orgTab_" + AREASKILLS} className='nav-item' data-qa-id="QA_org_skills">
										<a className={this.state.activeTab === AREASKILLS ? "active" : ""} href="#"
											onClick={() => this.toggle(AREASKILLS)}>{I('Skills requirements')}
										</a>
									</li>
								}
								<li role="presentation" id="orgTab_6" className="nav-item nav-page-toggle" data-qa-id="QA_org_working" style={{}}>
									<a className="arrow-right" href="#"
										onClick={() => this.handleChangeTab()}>
										<i className="icon-chevron-right" />
									</a>
								</li>

							</ul>}
						{tabPage == 2 &&
							<ul className="nav org-nav-tabs col-md-12">
								<li role="presentation" id="orgTab_7" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === AREAREPLACEMENT ? "active" : ""} href="#"
										onClick={() => this.toggle(AREAREPLACEMENT)}>{I('Replacement')}
									</a>
								</li>
								<li role="presentation" id="orgTab_8" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === AREAAUTOFORWARD ? "active" : ""} href="#"
										onClick={() => this.toggle(AREAAUTOFORWARD)}>{I('Automatic forward to external')}
									</a>
								</li>
								<li role="presentation" id="orgTab_9" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === AREASMS ? "active" : ""} href="#"
										onClick={() => this.toggle(AREASMS)}>{"SMS"}
									</a>
								</li>
								<li role="presentation" id="orgTab_10" className="nav-item" data-qa-id="QA_org_working">
									<a className={this.state.activeTab === AREAFORWARDEXTERNAL ? "active" : ""} href="#"
										onClick={() => this.toggle(AREAFORWARDEXTERNAL)}>{I('External Forward Addresses')}
									</a>
								</li>
								<li role="presentation" id="orgTab_11" className="nav-item nav-page-toggle" data-qa-id="QA_org_working" style={{}}>
									<a className="arrow-left" href="#"
										onClick={() => this.handleChangeTab()}>
										<i className="icon-chevron-left" />
									</a>
								</li>

							</ul>}
						<div className="row">
							<div className="col-lg-12 dot-row">
								<span onClick={() => this.handleChangeTab(2)} className={tabPage === 1 ? "dot active" : "dot"}></span>
								<span onClick={() => this.handleChangeTab(1)} className={tabPage === 2 ? "dot active" : "dot"}></span>
							</div>
						</div>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId={AREA}>
								<AreaForm
									area={this.state.area}
									saveOrganization={this.handleSaveArea}
									languages={this.state.languages}
									archiveImages={this.state.archiveImages}
									saving={this.state.saving}
									cancel={this.props.onCloseEdit}
								/>
							</TabPane>
							{element}
						</TabContent>
					</div>
				</div>
			</div>
		);
	}
}

const Label = ({ text }) => <label className="col-sm-12 col-md-2">{text}:</label>;

function boolToZeroOne(bool) {
	if (bool) {
		return 1;
	}
	return 0;
}

function zeroOneToBool(zeroOrOne) {
	if (typeof zeroOrOne === "string") {
		zeroOrOne = parseInt(zeroOrOne, 10);
	}
	return !!zeroOrOne;
}

const PCI = ({ onSelect, options, value }) => {
	if (!value) {
		return null;
	}
	return (
		<div className="row">
			<div className="col-lg-6">
				<div className="form-group-org">
					<div className="col-sm-12 col-lg-12">
						<SelectInputRow
							id="PCI"
							name="PCI"
							className="PCI"
							label={I('PCI - auto delete attachments')}
							option={formatValueToNameObj(options, "id", "value")}
							value={boolToZeroOne(value.enabled)}
							onSelect={onSelect}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

class Input extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(e) {
		let { value } = e.target;
		if (typeof value === "string") {
			value = parseInt(value, 10);
		}
		this.props.onChange(value, e);
	}
	render() {
		const { className, name, onChange, value } = this.props;
		return (
			<input
				name={name}
				className={className}
				type="text"
				value={value}
				onChange={this.handleChange}
			/>
		);
	}
}

const PCIHoursKeepAttachment = ({ onChange, value }) => {
	if (!value || !value.enabled) {
		return null;
	}
	return (
		<div className="row">
			<div className="col-lg-6">
				<div className="form-group-org">
					<label className="col-lg-12">{I("Hours to keep attachments")} :</label>
					<div className="col-lg-12">
						<Input
							className="form-control input-sm"
							type="text"
							value={value.hoursKeepAttachments}
							onChange={onChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

const TextInput = ({ className, name, onChange, value }) => (
	<div className={className}>
		<input
			name={name}
			className="form-control input-sm"
			type="text"
			value={value}
			onChange={onChange}
		/>
	</div>
);

const ExtFwdNumber = ({ onChange, value }) => (
	<div className="form-group-org">
		<label className="col-lg-12">{I("Number for external automatic forwarding")}:</label>
		<TextInput
			className="col-lg-12"
			name="extFwdNumber"
			onChange={onChange}
			value={value}
		/>
	</div>
);

class AreaForm extends Component {
	constructor(props) {
		super(props);
		this.handleChangePCIHoursKeepAttachment = this.handleChangePCIHoursKeepAttachment.bind(this);
		this.handlePCIAutoDeleteAttachments = this.handlePCIAutoDeleteAttachments.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.selectedEmailServers = [];
		this.newServerEmailId = -1;
		this.selectedAvailableGroups = "";
		this.state = {
			languages: [],
			archiveImages: [],
			salutationList: [],
			signatureList: [],
			organisationId: 0,
			organisationList: [],
			name: "",
			id: 0,
			replyToEmailAddress: "",
			newServerEmail: "",
			replyToName: "",
			timeZoneId: 0,
			chatAreaUrl: "",
			chatExternalId: "",
			daysToKeepErrand: 0,
			defaultBanner: "",
			defaultSalutation: 0,
			defaultSignature: 0,
			defaultSubjectPrefix: "",
			description: "",
			doQuickSearch: "",
			emailAddressList: [],
			errandExpireTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			collabErrandExpireTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			expectedDoneDateNote: "",
			externalChatId: "",
			externalExpertFrom: "",
			externalExpertsByEmail: false,
			excludeAnonymize: "",
			externalId: "",
			externalPriority: 0,
			generalExpireTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			generalWarningExpireTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			hasChat: false,
			hasVoice: false,
			disableAdminFbPostComments:true,
			hasSevenDaysWhatsapp: false,
			sipOutgoingDialPlanId: "",
			sipQueueId: "",
			sipExtFwdNumber: "",
			enabledPredefinedChatMessage: false,
			predefinedChatMessage: "",
			htmlBanner: "",
			htmlexpectedDoneDateNote: "",
			libraryId: 0,
			libraryForAI: 0,
			libraryList: [],
			mailAccountRules: {
				receiptActiveFrom: "00:00",
				receiptActiveTo: "23:59",
				receiptByTimeInterval: false,
				receiptHtmlBody: "",
				receiptSubject: "",
				receiptTextBody: "",
				receiptOutOfActiveHourSelected: 0,
				receiptHtmlBodyOutOfActiveHour: "",
				receiptSubjectOutOfActiveHour: "",
				receiptTextBodyOutOfActiveHour: "",
				reoccuringReceipt: 0,
				reoccuringReceiptBody: "",
				reoccuringReceiptHtmlBody: "",
				reoccuringReceiptInterval: 1,
				reoccuringReceiptSubject: "",
				selected: 0,
				requireReceipt: false
			},
			hasPostpone: false,
			dueDate: {
				dueDateSelected: 0,
				hasDoneDateNotification: false,
				doneDateAddress: "",
				expectedDoneDateNote: ""
			},
			maxAllowedQueuedChatSessions: 0,
			noteToExternalExperts: "",
			outboundPhoneId: 0,
			extFwdNumber: "",
			ownEmails: [],
			partialAlertTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			partialExpireTime: {
				days: 0,
				hours: 0,
				mins: 0
			},
			partialReplyWarningInterval: {
				days: 0,
				hours: 0,
				mins: 0
			},
			preFilledExternalExpert: "",
			proActiveChat: "",
			quickSearchCircularId: "",
			receiptEmailAddress: "",
			removeErrandFromArea: false,
			routingGroups: [],
			areaList: [],
			dueDateAreaId: 0,
			solidusPriorityServiceGroupId: 0,
			solidusServiceGroupId: 0,
			solidusServiceGroupIdsForChat: "",
			onlyAdminCanChangeSalutation: false,
			onlyAdminCanChangeSignature: false,
			selectedEmailServer: "",
			showNameError: false,
			showReplyToError: false,
			agentGroupListing: {},
			agentGroupSelected: {},
			prefixSubject: "",
			uploadFileName: "",
			uploadLocalFileName: "",
			anonymizeDay: 0,
			anonymizeContact: false,
			feedbackSendOutDelay: 0,
			blackoutPeriodAfterSurvey: 0,
			showArea: false,
			customAIInstructions: "",
			customAIResponse: "",
			autoAnswer: false,
			systemPromptText: "",
			instructPromptText: "",
		}
	}
	handlePCIAutoDeleteAttachments(v, n) {
		const pci = {
				enabled: zeroOneToBool(v)
				, hoursKeepAttachments: this.state.pci.hoursKeepAttachments
			}
			;
		this.setState({ pci });
	}
	handleChangePCIHoursKeepAttachment(value, e) {
		this.setState({
			pci: {
				enabled: this.state.pci.enabled
				, hoursKeepAttachments: value
			}
		});
	}
	handleCkeditorChange = (event) => {
		let name = event.editor.name;
		this.setState({ [name]: event.editor.getData() });
	}
	
	static getDerivedStateFromProps(props, state) {
		if(JSON.stringify(props.area) === "{}") {
				return null;
		}
		if (props.area != state.area) {
			let area = props.area;
			let rules = props.area ? props.area.mailAccountRules: {};
			return {	
				languages: area.languages,
				archiveImages: area.areaFileArchive,
				salutationList: area.salutationList,
				signatureList: area.signatureList,
				organisationId: area.organisationId,
				organisationList: area.organisationList,
				name: area.name,
				id: area.id,
				replyToEmailAddress: area.replyToEmailAddress,
				replyToName: area.replyToName,
				timeZoneId: area.timeZoneId,
				chatAreaUrl: area.chatAreaUrl,
				chatExternalId: area.chatExternalId,
				daysToKeepErrand: area.daysToKeepErrand,
				defaultBanner: area.defaultBanner,
				defaultSalutation: area.defaultSalutation,
				defaultSignature: area.defaultSignature,
				defaultSubjectPrefix: area.defaultSubjectPrefix,
				description: area.description,
				doQuickSearch: area.doQuickSearch,
				emailAddressList: area.emailAddressList,
				expectedDoneDateNote: area.expectedDoneDateNote,
				externalChatId: area.externalChatId,
				externalExpertFrom: area.externalExpertFrom,
				externalExpertsByEmail: area.externalExpertsByEmail,
				externalId: area.externalId,
				externalPriority: area.externalPriority,
				hasChat: area.hasChat,
				hasVoice: area.hasVoice,
				disableAdminFbPostComments: area.disableAdminFbPostComments,
				hasSevenDaysWhatsapp: area.hasSevenDaysWhatsapp,
				sipOutgoingDialPlanId: area.sipOutgoingDialPlanId,
				sipQueueId: area.sipQueueId,
				sipExtFwdNumber: area.sipExtFwdNumber,
				enabledPredefinedChatMessage: area.enabledPredefinedChatMessage,
				predefinedChatMessage: area.predefinedChatMessage,
				hasPostpone: area.hasPostpone,
				htmlBanner: area.htmlBanner,
				htmlexpectedDoneDateNote: area.htmlexpectedDoneDateNote,
				libraryId: area.libraryId,
				libraryForAI: area.libraryForAI,
				libraryList: area.libraryList,
				maxAllowedQueuedChatSessions: area.maxAllowedQueuedChatSessions,
				noteToExternalExperts: area.noteToExternalExperts,
				ownEmails: area.ownEmails,
				preFilledExternalExpert: area.preFilledExternalExpert,
				proActiveChat: area.proActiveChat,
				quickSearchCircularId: area.quickSearchCircularId,
				receiptEmailAddress: area.receiptEmailAddress,
				removeErrandFromArea: area.removeErrandFromArea,
				routingGroups: area.routingGroups,
				areaList: area.areaList,
				dueDateAreaId: parseInt(area.dueDateAreaId),
				solidusPriorityServiceGroupId: area.solidusPriorityServiceGroupId,
				solidusServiceGroupId: area.solidusServiceGroupId,
				solidusServiceGroupIdsForChat: area.solidusServiceGroupIdsForChat,
				onlyAdminCanChangeSalutation: area.onlyAdminCanChangeSalutation,
				onlyAdminCanChangeSignature: area.onlyAdminCanChangeSignature,
				agentGroupListing: area.agentGroupListing,
				agentGroupSelected: area.agentGroupSelected,
				prefixSubject: area.prefixSubject,
				mailAccountRules: {
					//...prevState.mailAccountRules,
					receiptActiveFrom: rules ? rules.receiptActiveFrom : "",
					receiptActiveTo: rules ? rules.receiptActiveTo : "",
					receiptByTimeInterval: area.receiptByTimeInterval,
					receiptHtmlBody: rules ? rules.receiptHtmlBody: "",
					receiptSubject: rules ? rules.receiptSubject: "",
					receiptTextBody: rules ? rules.receiptTextBody: "",
					receiptOutOfActiveHourSelected: rules ? rules.receiptOutOfActiveHourSelected: "",
					receiptHtmlBodyOutOfActiveHour: rules ? rules.receiptHtmlBodyOutOfActiveHour: "",
					receiptSubjectOutOfActiveHour: rules ? rules.receiptSubjectOutOfActiveHour: "",
					receiptTextBodyOutOfActiveHour: rules ? rules.receiptTextBodyOutOfActiveHour: "",
					reoccuringReceipt: rules ? rules.reoccuringReceipt: "",
					reoccuringReceiptBody: rules ? rules.reoccuringReceiptBody: "",
					reoccuringReceiptHtmlBody: rules ? rules.reoccuringReceiptHtmlBody: "",
					reoccuringReceiptInterval: rules ? rules.reoccuringReceiptInterval: "",
					reoccuringReceiptSubject: rules ? rules.reoccuringReceiptSubject: "",
					selected: rules ? rules.selected : "",
					requireReceipt: rules ? rules.requireReceipt: ""
				},
				outboundPhoneId: area.outbound ? area.outbound.outboundPhoneId : 0,
				extFwdNumber: area.extFwdNumber,
				dueDate: {
					//...prevState.dueDate,
					dueDateSelected: area && area.dueDate ? area.dueDate.dueDateSelected : "",
					hasDoneDateNotification: area && area.dueDate ?area.dueDate.hasDoneDateNotification: "",
					doneDateAddress: area && area.dueDate ? area.dueDate.doneDateAddress: "",
					expectedDoneDateNote: area && area.dueDate ? area.dueDate.expectedDoneDateNote : ""
				},
				partialAlertTime: {
					//...prevState.partialAlertTime,
					days: area && area.partialAlertTime ? area.partialAlertTime.days: "",
					hours: area && area.partialAlertTime ? area.partialAlertTime.hours: "",
					mins: area && area.partialAlertTime ? area.partialAlertTime.mins: "",
				},
				partialExpireTime: {
					//...prevState.partialExpireTime,
					days: area && area.partialExpireTime ? area.partialExpireTime.days: "",
					hours: area && area.partialExpireTime ? area.partialExpireTime.hours: "",
					mins: area && area.partialExpireTime ? area.partialExpireTime.mins: "",
				},
				partialReplyWarningInterval: {
					//...prevState.partialReplyWarningInterval,
					days: area && area.partialReplyWarningInterval ? area.partialReplyWarningInterval.days: "",
					hours: area && area.partialReplyWarningInterval ? area.partialReplyWarningInterval.hours: "",
					mins: area && area.partialReplyWarningInterval ? area.partialReplyWarningInterval.mins: "",
				},
				errandExpireTime: {
					//...prevState.errandExpireTime,
					days: area && area.errandExpireTime ? area.errandExpireTime.days: "",
					hours: area && area.errandExpireTime ? area.errandExpireTime.hours: "",
					mins: area && area.errandExpireTime ? area.errandExpireTime.mins:"",
				},
				collabErrandExpireTime: {
					//...prevState.collabErrandExpireTime,
					days: area && area.collabErrandExpireTime ? area.collabErrandExpireTime.days: "",
					hours: area && area.collabErrandExpireTime ? area.collabErrandExpireTime.hours: "",
					mins: area && area.collabErrandExpireTime ? area.collabErrandExpireTime.mins:"",
				},
				generalExpireTime: {
					//...prevState.generalExpireTime,
					days: area && area.generalExpireTime ? area.generalExpireTime.days: "",
					hours: area && area.generalExpireTime ? area.generalExpireTime.hours: "",
					mins: area && area.generalExpireTime ? area.generalExpireTime.mins: "",
				},
				generalWarningExpireTime: {
					//...prevState.generalWarningExpireTime,
					days: area && area.generalWarningExpireTime ? area.generalWarningExpireTime.days: "",
					hours: area && area.generalWarningExpireTime ? area.generalWarningExpireTime.hours: "",
					mins: area && area.generalWarningExpireTime ? area.generalWarningExpireTime.mins: "",
				},
				anonymizeDay: area.anonymizeDay,
				anonymizeContact: area.anonymizeContact,
				excludeAnonymize: area.excludeAnonymize,
				pci: area.pci,
				feedbackSendOutDelay: area.feedbackSendOutDelay,
				blackoutPeriodAfterSurvey: area.blackoutPeriodAfterSurvey,
				area: props.area,
				customAIInstructions: area.customAIInstructions,
				customAIResponse: area.customAIResponse,
				autoAnswer: area.autoAnswer,
				systemPromptText: area.systemPromptText,
				instructPromptText: area.instructPromptText,
			};
		}
		return null;
	}
	
	handleAddIntoList = (src, des, selectedIDs) => {
		var newList = des.slice();
		selectedIDs.split(",").map(function (id, i) {
			id = parseInt(id);
			src.map(function (keys, index) {
				if (id == keys.id) {
					newList.push(keys);
				}
			});
		});
		return newList;
	}
	handleRemoveFromList = (src, selectedIDs) => {
		var newList = src.slice();
		selectedIDs.split(",").map(function (id, i) {
			id = parseInt(id);
			newList.map(function (keys, index) {
				if (id == keys.id) {
					newList.splice(index, 1);
				}
			});
		});
		return newList;
	}
	handleAddGroups = () => {
		var members = this.handleAddIntoList(this.state.agentGroupListing, this.state.agentGroupSelected, this.selectedAvailableGroups);
		var available = this.handleRemoveFromList(this.state.agentGroupListing, this.selectedAvailableGroups);
		this.selectedAddedMembers = this.selectedAddedMembers + "," + this.selectedAvailableGroups;
		this.selectedAvailableGroups = "";
		this.setState({
			agentGroupListing: available,
			agentGroupSelected: members,
		});
	}
	handleRemoveGroups = () => {
		var available = this.handleAddIntoList(this.state.agentGroupSelected, this.state.agentGroupListing, this.selectedAddedMembers);
		var members = this.handleRemoveFromList(this.state.agentGroupSelected, this.selectedAddedMembers);
		this.selectedAvailableGroups = this.selectedAvailableGroups + "," + this.selectedAddedMembers;
		this.selectedAddedMembers = "";
		this.setState({
			agentGroupListing: available,
			agentGroupSelected: members,
		});
	}
	onSelectAvailable = (id) => {
		this.selectedAvailableGroups = id;
	}
	onSelectGroup = (id) => {
		this.selectedAddedMembers = id;
	}
	expireTimesToJson = () => {
		let expireTimeMap = {}
		expireTimeMap.errandExpireTime = this.state.errandExpireTime;
		expireTimeMap.collabErrandExpireTime = this.state.collabErrandExpireTime;
		expireTimeMap.partialExpireTime = this.state.partialExpireTime;
		expireTimeMap.partialReplyWarningInterval =
			this.state.partialReplyWarningInterval;
		expireTimeMap.generalWarningExpireTime =
			this.state.generalWarningExpireTime;
		expireTimeMap.partialAlertTime = this.state.partialAlertTime;
		expireTimeMap.generalExpireTime = this.state.generalExpireTime;

		return JSON.stringify(expireTimeMap)
	}
	toJson() {
		let s = JSON.parse(JSON.stringify(this.state));
		if (typeof s.pci !== "undefined") {
			s.pci = JSON.stringify(this.state.pci);
		}
		s.agentGroupMap = JSON.stringify(this.state.agentGroupSelected);
		s.emailAddressMap = JSON.stringify(this.state.emailAddressList);
		s.dueDateMap = JSON.stringify(this.state.dueDate);
		s.expireTimeMap = this.expireTimesToJson();
		s.mailAccountRulesMap = JSON.stringify(this.state.mailAccountRules);
		delete s.agentGroupSelected;
		delete s.agentGroupListing;
		delete s.emailAddressList;
		delete s.dueDate;
		delete s.ownEmails;
		delete s.libraryList;
		delete s.organisationList;
		delete s.signatureList;
		delete s.archiveImages;
		delete s.languages;
		delete s.errandExpireTime;
		delete s.collabErrandExpireTime;
		delete s.partialExpireTime;
		delete s.partialReplyWarningInterval;
		delete s.generalWarningExpireTime;
		delete s.partialAlertTime;
		delete s.generalExpireTime;
		delete s.mailAccountRules;
		delete s.salutationList;
		delete s.areaList;
		return s
	}
	saveArea = (event) => {
		// if (typeof this.state.uploadFileName == 'undefined' || this.state.uploadFileName == "") {
		// 	if (this.state.name == "" || this.state.name.trim().length == 0) {
		// 		this.setState({ showNameError: true });
		// 		return;
		// 	}
		// 	if (this.state.replyToEmailAddress == ""
		// 		|| this.state.replyToEmailAddress.trim().length == 0) {
		// 		this.setState({ showReplyToError: true });
		// 		return;
		// 	}
		// }
		let s = this.toJson();
		s.areaSettingPresent = true;
		this.props.saveOrganization(AREA, s);
	}
	handleExpireTimeChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name.split('_')[0];
		const expireType = target.name.split('_')[1];
		let val = 0;
		if (isNaN(value) == false) {
			val = Number(value);
		}
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[expireType]: val
			}
		}));
	}
	handleSubInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name.split('_')[0];
		const expireType = target.name.split('_')[1];
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[expireType]: value
			}
		}), () => {
			//console.log('', this.state.mailAccountRules.selected);
		});
	}
	handleSubInputChangeInt = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name.split('_')[0];
		const expireType = target.name.split('_')[1];
		if (isNaN(value) == true) {
			console.info("value for " + target.name + "is not a number");
			return;
		}
		let newVal = parseInt(value);
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[expireType]: newVal
			}
		}));
	}
	handleChangeSubData = (a, b) => {
		const value = a;
		const name = b.split('_')[0];
		const expireType = b.split('_')[1];
		if (isNaN(value) == true) {
			console.info("value for " + name + "is not a number");
			return;
		}
		let newVal = parseInt(value);
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[expireType]: newVal
			}
		}));
	}
	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let nameState = this.state.showNameError;
		if (name == "name") {
			nameState = false;
		}
		let replyToEmailState = this.state.showReplyToError;
		if (name == "replyToEmailAddress") {
			replyToEmailState = false;
		}
		this.setState({
			[name]: value,
			showNameError: nameState,
			showReplyToError: replyToEmailState
		});
	}
	handleSwitchBoxCheck = (checked, state) => {
		const name = state;
		const value = !checked;
		this.setState({
			[name]: value
		});
	}
	handleSubSwitchBoxCheck = (checked, state) => {
		const name = state.split('_')[0];
		const expireType = state.split('_')[1];
		const value = !checked;
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[expireType]: value
			}
		}));
	}
	handleSubCkeditorChange = (event) => {
		const value = event.editor.getData()
		const name = event.editor.name.split('_')[0];
		const field = event.editor.name.split('_')[1];
		this.setState(prevState => ({
			[name]: {
				...prevState[name],
				[field]: value
			}
		}));
	}
	handleReceiptActiveFrom = (value) => {
		this.setState(prevState => ({
			mailAccountRules: {
				...prevState.mailAccountRules,
				receiptActiveFrom: value
			}
		}));
	}
	handleReceiptActiveTo = (value) => {
		this.setState(prevState => ({
			mailAccountRules: {
				...prevState.mailAccountRules,
				receiptActiveTo: value
			}
		}));
	}
	handleEndChange = (event) => {

	}
	handleKeyDown = (event) => {

	}
	onSelectEmailServer = (value) => {
		this.selectedEmailServers = value;
	}
	sortServerEmail = (a, b) => {
		if (a.order < b.order) {
			return -1
		} else if (a.order > b.order) {
			return 1
		} else {
			return 0
		}
	}
	reOrder = (oldList) => {
		for (var i = 0; i < oldList.length; i++) {
			oldList[i].order = i + 1;
		}
		return oldList;
	}
	handleAddServerEmail = (event) => {
		var newList = this.state.emailAddressList;
		for (var i = 0; i < newList.length; i++) {
			if (newList[i].name == this.state.newServerEmail) {
				this.setState({
					newServerEmail: ""
				});
				return;
			}
		}
		var newEntry = {
			'id': this.newServerEmailId,
			'name': this.state.newServerEmail,
			'order': newList.length + 1
		};
		this.newServerEmailId--;
		if (this.state.newServerEmail.length > 0) {
			if (newList == null) {
				newList = [];
			}
			newList.push(newEntry);
			this.setState({
				emailAddressList: newList,
				newServerEmail: ""
			});
			this.selectedEmailServers = "" + newEntry.id;
		}
	}
	handleAddPlaceholder = (placeholder, id) => {
		this.setState({
			[id]: this.state[id] + placeholder
		});
	}
	handleRemoveServerEmail = (event) => {
		if (this.selectedEmailServers.length == 0) {
			return
		}
		var newArray = [];
		var idArray = this.selectedEmailServers.split(',');
		for (var j = 0; j < this.state.emailAddressList.length; j++) {
			for (var i = 0; i < idArray.length; i++) {
				if (idArray[i] != this.state.emailAddressList[j].id) {
					newArray.push(this.state.emailAddressList[j]);
				}
			}
		}
		newArray.sort(this.sortServeremail);
		newArray = this.reOrder(newArray);
		this.setState({
			emailAddressList: newArray
		});
	}
	handleServerEmailUp = (event) => {
		if (this.selectedEmailServers.length == 0) {
			return
		}
		let newList = this.state.emailAddressList;
		this.selectedEmailServers.split(",").forEach(function (anId) {
			let foundIndex = newList.findIndex(a => a.id == anId);
			if (foundIndex != -1) {
				var address = newList[foundIndex];
				if (address.order == 1) {
					return newList;
				}
				let newOrder = address.order - 1;
				this.state.emailAddressList.forEach(
					function (emailAdd, i) {
						if (newOrder == emailAdd.order) {
							emailAdd.order++;
							newList[i] = emailAdd;
							return
						}
					}
				);
				address.order = newOrder;
				newList[foundIndex] = address
			}
		}.bind(this));
		this.setState({
			emailAddressList: newList
		});
	}
	handleServerEmailDown = (event) => {
		if (this.selectedEmailServers.length == 0) {
			return
		}
		let newList = this.state.emailAddressList;
		this.selectedEmailServers.split(",").forEach(function (anId) {
			let foundIndex = newList.findIndex(a => a.id == anId);
			if (foundIndex != -1) {
				var address = newList[foundIndex];
				if (address.order == newList.length) {
					return newList;
				}
				let newOrder = address.order + 1;
				this.state.emailAddressList.forEach(
					function (emailAdd, i) {
						if (newOrder == emailAdd.order) {
							emailAdd.order--;
							newList[i] = emailAdd;
							return
						}
					}
				);
				address.order = newOrder;
				newList[foundIndex] = address
			}
		}.bind(this));
		this.setState({
			emailAddressList: newList
		});
	}
	onSelectDueDateArea = (id) => {
		this.setState({
			dueDateAreaId: id
		});
	}
	handleToggleArea() {
		this.setState({
			showArea: !this.state.showArea
		});
	}
	handleFileUpload = (data) => {
		this.setState({
			uploadFileName: data.value,
			uploadLocalFileName: data.id
		});
	}
	handleFeedbackDelay = (event) => {
		this.setState({
			feedbackSendOutDelay: event.target.value
		});
	}
	handleBlackoutPeriodAfterSurvey = (event) => {
		this.setState({
			blackoutPeriodAfterSurvey: event.target.value
		});
	}
	handleChangeData = (a, b) => {
		const name = b;
		this.setState({
			[name]: a
		});
	}
	render = () => {
		let p = this.props.area;
		let s = this.state;
		let receiveActions = [{ id: 0, value: I('No') }, { id: 1, value: I('Yes') }, { id: 2, value: I('Time Interval') }];
		let reoccurActions = [{ id: 0, value: I('No') }, { id: 1, value: I('Yes') }];
		let dueDate = [{ id: 0, value: I('No') }, { id: 1, value: I('Yes') }];
		let intervalDate = () => {
			let options = [];
			for (let i = 1; i < 30; i++) {
				options.push(<option key={i} value={i}>{i}</option>)
			}
			return options;
		}
		var uploadUrl = "admin/org/" + s.organisationId + "/uploadAreaList";
		if (p === 'undefined')
			return null;
		if (p.timezoneList && p.timezoneList[0].id !== 0) {
			p.timezoneList.unshift({ abbrZoneName: "ORG", id: 0, label: "Use Organisation Setting", zoneName: "Org/Setting" });
		}
		let receiptAddress = <div className="form-group-org">
			<label className="col-sm-12 col-md-2">{I("Receipt address")}:<span className="mandatory">* </span></label>
			<div className="col-sm-8 col-md-3">
				<input name="receiptEmailAddress" className="form-control input-sm" type="text" value={s.receiptEmailAddress} onChange={this.handleInputChange} />
			</div>
		</div>;

		return (<div className="area-form">
			<div className="row select-row">
				<div className="col-lg-6">
					<div className="form-group-org">
						<div className="col-lg-12">
							<SelectInputRow
								id="organisationId"
								name="organisationId"
								className="organisationId"
								label={I('Organization')}
								option={formatValueToNameObj(p.organisationList, "id", "name")}
								value={s.organisationId}
								onSelect={this.handleChangeData}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="form-group-org">
						<label className="col-lg-12">{I("Name")}:<span className="mandatory">* </span></label>
						<div className="col-lg-12">
							<input name="name" className="form-control input-sm" type="text" value={s.name} onChange={this.handleInputChange} />
						</div>
						<label style={{ display: (this.state.showNameError ? 'block' : 'none'), color: 'red' }} className="col-lg-6">{I("Name field can't be empty")}</label>
					</div>
				</div>
			</div>
			<div className="row select-row">
				<Feature tag="cention-workflow">
					<div className="col-lg-6">
						<div>
							<div className="form-group-org">
								<label className="col-lg-12">{I("Reply-to address")}:<span className="mandatory">* </span></label>
								<div className="col-lg-12">
									<input name="replyToEmailAddress" className="form-control input-sm" type="text" value={s.replyToEmailAddress} onChange={this.handleInputChange} />
								</div>
								<label style={{ display: (this.state.showReplyToError ? 'block' : 'none'), color: 'red' }} className="col-lg-6">{I("Reply-to address field must not be empty")}</label>
							</div>
						</div>
					</div>
				</Feature>
				<div className="col-lg-6">
					<div className="form-group-org">
						<label className="col-lg-12">{I("Name in reply-to address")}:</label>
						<div className="col-lg-12">
							<input name="replyToName" className="form-control input-sm" type="text" value={s.replyToName} onChange={this.handleInputChange} />
						</div>
					</div>
				</div>
			</div>
			<Feature tag="cention-workflow">
				{typeof p.id === 'undefined' && <div style={{ display: "none" }}>
					<div className="form-group-org">
						<label className="col-sm-12 col-md-2">{I("Upload Area List")}:</label>
						<div className="col-sm-12 col-md-10">
							<table>
								<tbody>
									<tr>
										<td style={{ padding: "0 10px 0 0" }}>
											<FileUploader uploadTo={uploadUrl}
												onFileupload={this.handleFileUpload} />
										</td>
										<td style={{ padding: "0 10px 0 0" }}>
											<input name="uploadFileName" className="form-control input-sm" type="text" value={this.state.uploadFileName} readOnly />
										</td>
										<td>
											<Helper helper={I("Create a csv file to upload with the following information:")
												+ I("Area name, email address, name in reply-to address") + "<br />"
												+ I("All information should be put in one column (if done in Excel) and comma separated.") + "<br />"
												+ I("Example:") + "<br />"
												+ I("Sales, info@cention.com, Cention Sales Support, support@cention.com, Cention Support")
											} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>}
			</Feature>
			<Feature tag="cention-workflow">
				<Feature tag="show-time-zone">
					<div className="row select-row">
						<div className="col-lg-6">
							<div className="form-group-org">
								<div className="col-lg-12">
									{/* <select name="timeZoneId" value={s.timeZoneId} onChange={this.handleInputChange} className="form-control input-sm">
										<option value={0}>{I("Use Organization Setting")}</option>
										{p.timezoneList && p.timezoneList.map((tz) => <option key={tz.id} value={tz.id}>{tz.label}</option>)}
									</select> */}
									<SelectInputRow
										id="timezoneID"
										name="timeZoneId"
										className="usetimeZone"
										label={I('Time Zone')}
										option={formatValueToNameObj(p.timezoneList, "id", "label")}
										value={s.timeZoneId}
										onSelect={this.handleChangeData}
									/>
								</div>
							</div>
						</div>
					</div>
				</Feature>
			</Feature>
			<Feature tag="cention-workflow">
				<div className="field-row">
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("Hold time for 'My errands'")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="errandExpireTime_days" className="form-control input-sm" type="text" value={s.errandExpireTime ? s.errandExpireTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="errandExpireTime_hours" className="form-control input-sm" type="text" value={s.errandExpireTime ? s.errandExpireTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="errandExpireTime_mins" className="form-control input-sm" type="text" value={s.errandExpireTime ? s.errandExpireTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("Hold time for partly answered errands")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="partialExpireTime_days" className="form-control input-sm" type="text" value={s.partialExpireTime ? s.partialExpireTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="partialExpireTime_hours" className="form-control input-sm" type="text" value={s.partialExpireTime ? s.partialExpireTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="partialExpireTime_mins" className="form-control input-sm" type="text" value={s.partialExpireTime ? s.partialExpireTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("Hold time for collaboration errands")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="collabErrandExpireTime_days" className="form-control input-sm" type="text" value={s.collabErrandExpireTime ? s.collabErrandExpireTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="collabErrandExpireTime_hours" className="form-control input-sm" type="text" value={s.collabErrandExpireTime ? s.collabErrandExpireTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="collabErrandExpireTime_mins" className="form-control input-sm" type="text" value={s.collabErrandExpireTime ? s.collabErrandExpireTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("Warning time for partly answered errands")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="partialReplyWarningInterval_days" className="form-control input-sm" type="text" value={s.partialReplyWarningInterval ? s.partialReplyWarningInterval.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="partialReplyWarningInterval_hours" className="form-control input-sm" type="text" value={s.partialReplyWarningInterval ? s.partialReplyWarningInterval.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="partialReplyWarningInterval_mins" className="form-control input-sm" type="text" value={s.partialReplyWarningInterval ? s.partialReplyWarningInterval.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("General warning time")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="generalWarningExpireTime_days" className="form-control input-sm" type="text" value={s.generalWarningExpireTime ? s.generalWarningExpireTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="generalWarningExpireTime_hours" className="form-control input-sm" type="text" value={s.generalWarningExpireTime ? s.generalWarningExpireTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="generalWarningExpireTime_mins" className="form-control input-sm" type="text" value={s.generalWarningExpireTime ? s.generalWarningExpireTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("Expiration time for partly answered errands")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="partialAlertTime_days" className="form-control input-sm" type="text" value={s.partialAlertTime ? s.partialAlertTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="partialAlertTime_hours" className="form-control input-sm" type="text" value={s.partialAlertTime ? s.partialAlertTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="partialAlertTime_mins" className="form-control input-sm" type="text" value={s.partialAlertTime ? s.partialAlertTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form-group-org td-padding-right">
						<div className="row">
							<label className="col-lg-12">{I("General expire time")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<table>
									<tbody>
										<tr>
											<td><input name="generalExpireTime_days" className="form-control input-sm" type="text" value={s.generalExpireTime ? s.generalExpireTime.days : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("days")}</td>
											<td><input name="generalExpireTime_hours" className="form-control input-sm" type="text" value={s.generalExpireTime ? s.generalExpireTime.hours : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("hours")}</td>
											<td><input name="generalExpireTime_mins" className="form-control input-sm" type="text" value={s.generalExpireTime ? s.generalExpireTime.mins : ""} onChange={this.handleExpireTimeChange} /></td>
											<td>{I("minutes")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-lg-12">{I("External ID")}:</label>
								<div className="col-lg-12">
									<input name="externalId" className="form-control input-sm" type="text" value={s.externalId} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-lg-12">{I("Pre subject")}:</label>
								<div className="col-lg-12">
									<input name="prefixSubject" className="form-control input-sm" type="text" value={s.prefixSubject} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-lg-12">{I("External Chat ID")}:</label>
								<div className="col-lg-12">
									<input name="externalChatId" className="form-control input-sm" type="text" value={s.externalChatId} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<Feature tag="puzzel">
							<Feature tag="puzzel.area-vip-custom-value">
								<div className="col-lg-6">
									<div className="form-group-org">
										<label className="col-lg-12">{I("External queue vip score custom value")}:</label>
										<div className="col-lg-12">
											<input name="externalPriority" className="form-control input-sm" type="text" value={s.externalPriority} onChange={this.handleInputChange} />
										</div>
									</div>
								</div>
							</Feature>
						</Feature>
					</div>
					<Feature tag="callEnableTwilio">
						<div className="row">
							<div className="col-lg-6">
								<div className="form-group-org">
									<div className="col-lg-12">
										{p.outbound &&
											<SelectInputRow
												id="outboundPhoneId"
												name="outboundPhoneId"
												className="outboundPhoneId"
												label={I('Outbound Phone')}
												option={formatValueToNameObj(p.outbound.outboundList, "id", "name")}
												value={s.outboundPhoneId}
												onSelect={this.handleChangeData}
											/>}
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<ExtFwdNumber
									onChange={this.handleInputChange}
									value={s.extFwdNumber}
								/>
							</div>
						</div>
					</Feature>
					<Feature tag="quicksearch.enabled">
						<div className="row">
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-lg-12">{I("Enable Quick Search Integration")}:</label>
									<div className="col-lg-12">
										<SwitchCheckbox
											active={s.doQuickSearch}
											buttonClassName={""}
											id={"orgActiveSwitch"}
											name={"orgActive"}
											type="checkbox"
											onClick={() => { this.handleSwitchBoxCheck(s.doQuickSearch, "doQuickSearch") }}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-lg-12">{I("Quick Search CircularId")}:</label>
									<div className="col-lg-12">
										<input name="quickSearchCircularId" className="form-control input-sm" type="text" value={s.quickSearchCircularId} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
						</div>
					</Feature>
				</div>
			</Feature >
			<Feature tag="chat">
				<div className="field-row">
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Available Chat Slot")}:</label>
								<div className="col-sm-12 col-lg-12">
									<input name="proActiveChat" className="form-control input-sm" type="text" value={s.proActiveChat} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Has chat")}:</label>
								<div className="col-sm-12 col-lg-12">
									<SwitchCheckbox
										active={s.hasChat}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.hasChat, "hasChat") }}
									/>
								</div>
							</div>
						</div>
					{features["whatsapp-history-7days"] &&(
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Has a seven-day WhatsApp history")}:</label>
								<div className="col-sm-12 col-lg-12">
									<SwitchCheckbox
										active={s.hasSevenDaysWhatsapp}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.hasSevenDaysWhatsapp, "hasSevenDaysWhatsapp") }}
									/>
								</div>
							</div>
						</div>)}
						{features["facebook-admin-threads-close"] &&(
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Close Facebook comments and replies from administrator posts")}:</label>
								<div className="col-sm-12 col-lg-12">
									<SwitchCheckbox
										active={s.disableAdminFbPostComments}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.disableAdminFbPostComments, "disableAdminFbPostComments") }}
									/>
								</div>
							</div>
						</div>
						 )} 
						<Feature tag="sip-enabled">
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("Has voice")}:</label>
									<div className="col-sm-12 col-lg-12">
										<SwitchCheckbox
											active={s.hasVoice}
											buttonClassName={""}
											id={"orgActiveSwitch"}
											name={"orgActive"}
											type="checkbox"
											onClick={() => { this.handleSwitchBoxCheck(s.hasVoice, "hasVoice") }}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("Outgoing SIP trunk Id")}:</label>
									<div className="col-sm-12 col-lg-12">
										<input name="sipOutgoingDialPlanId" className="form-control input-sm" type="text" value={s.sipOutgoingDialPlanId} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("SIP Queue Id")}:</label>
									<div className="col-sm-12 col-lg-12">
										<input name="sipQueueId" className="form-control input-sm" type="text" value={s.sipQueueId} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("SIP Forward To Number")}:</label>
									<div className="col-sm-12 col-lg-12">
										<input name="sipExtFwdNumber" className="form-control input-sm" type="text" value={s.sipExtFwdNumber} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
						</Feature>
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Chat URL name")}:</label>
								<div className="col-sm-12 col-lg-12">
									<input name="chatAreaUrl" className="form-control input-sm" type="text" value={s.chatAreaUrl} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<Feature tag="chat.max-queued-sessions.admin-edit">
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("Number of allowed queued chat sessions")}:</label>
									<div className="col-sm-12 col-lg-12">
										<input name="maxAllowedQueuedChatSessions" className="form-control input-sm" type="text" value={s.maxAllowedQueuedChatSessions} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
						</Feature>
						<Hideable hidden={PC_RECEIPT_GREETING}>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("Enable predefined chat message")}:</label>
									<div className="col-sm-12 col-lg-12">
										<SwitchCheckbox
											active={s.enabledPredefinedChatMessage}
											buttonClassName={""}
											id={"orgActiveSwitch"}
											name={"orgActive"}
											type="checkbox"
											onClick={() => { this.handleSwitchBoxCheck(s.enabledPredefinedChatMessage, "enabledPredefinedChatMessage") }}
										/>
									</div>
								</div>
							</div>
							{s.enabledPredefinedChatMessage &&
								<div className="col-lg-6">
									<div className="form-group-org">
										<label className="col-sm-12 col-lg-12">{I("Predefined chat message")}:</label>
										<div className="col-sm-12 col-lg-12">
											<input name="predefinedChatMessage" className="form-control input-sm" type="text" value={s.predefinedChatMessage} onChange={this.handleInputChange} />
										</div>
									</div>
								</div>
							}
						</Hideable>
					</div>
				</div>
			</Feature>
			<Feature tag="cention-workflow">
				<div className="field-row">
					<div className="row">
						<Feature tag="solidusEnabled">
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-lg-12">{I("Solidus Service Group ID")}:</label>
									<div className="col-lg-12">
										<input name="solidusServiceGroupId" className="form-control input-sm" type="text" value={s.solidusServiceGroupId} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group-org">
									<label className="col-lg-12">{I("Prioritized Solidus Service Group ID")}:</label>
									<div className="col-lg-12">
										<input name="solidusPriorityServiceGroupId" className="form-control input-sm" type="text" value={s.solidusPriorityServiceGroupId} onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
							<Feature tag="chat">
								<div className="form-group-org">
									<label className="col-lg-11">{I("Solidus Service Group ID's for chat")}:</label>
									<div className="col-lg-11">
										<input name="solidusServiceGroupIdsForChat" className="form-control input-sm" type="text" value={s.solidusServiceGroupIdsForChat} onChange={this.handleInputChange} />
									</div>
									<div className="col-lg-1">
										<Helper helper={I("Here you can enter a comma separated list of Solidus Service Group IDs that should be checked for available agents when the system decides which agents a chat can be forwarded to.") + "<br />"
											+ I("If nothing is entered in this field the system will check the Service Group entered in the field above.")
										} />
									</div>
								</div>
							</Feature>
						</Feature>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("Banner")}:</label>
							<div className="col-sm-12 col-lg-12">
								<Ckeditor
									id={"defaultBanner"}
									ref={"defaultBanner"}
									locationHash={window.location.hash}
									defaultFontFamily={s.features}
									defaultFontSize={s.features}
									spellLanguages={s.languages || []}
									defaultContent={s.defaultBanner || ""}
									showResize={false}
									simpleToolbar={false}
									fileArchiveImages={s.archiveImages || []}
									onChange={this.handleCkeditorChange}
									onKeydown={this.handleKeyDown}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="form-group-org">
							<div className="col-sm-12 col-lg-12">
								<SelectInputRow
									id="defaultSalutation"
									name="defaultSalutation"
									className="defaultSalutation"
									label={I('Default Salutation')}
									option={formatValueToNameObj(p.salutationList, "id", "name")}
									value={s.defaultSalutation == "" ? 0 : s.defaultSalutation}
									onSelect={this.handleChangeData}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("Only administrators can change salutation")}:</label>
							<div className="col-sm-12 col-lg-12">
								<SwitchCheckbox
									active={s.onlyAdminCanChangeSalutation}
									buttonClassName={""}
									id={"orgActiveSwitch"}
									name={"orgActive"}
									type="checkbox"
									onClick={() => { this.handleSwitchBoxCheck(s.onlyAdminCanChangeSalutation, "onlyAdminCanChangeSalutation") }}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="form-group-org">
							<div className="col-sm-12 col-lg-12">
								<SelectInputRow
									id="defaultSignature"
									name="defaultSignature"
									className="defaultSignature"
									label={I('Default Signature')}
									option={formatValueToNameObj(p.signatureList, "id", "name")}
									value={s.defaultSignature == "" ? 0 : s.defaultSignature}
									onSelect={this.handleChangeData}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("Only administrators can change signature")}:</label>
							<div className="col-sm-12 col-lg-12">
								<SwitchCheckbox
									active={s.onlyAdminCanChangeSignature}
									buttonClassName={""}
									id={"orgActiveSwitch"}
									name={"orgActive"}
									type="checkbox"
									onClick={() => { this.handleSwitchBoxCheck(s.onlyAdminCanChangeSignature, "onlyAdminCanChangeSignature") }}
								/>
							</div>
						</div>
					</div>
					<Feature tag="cention-library">
						<div className="col-lg-12 col-sm-12">
							<div className="form-group-org">
								{/* <label className="col-sm-12 col-lg-12">{I("Default Knowledge base")}:</label> */}
								<div className="col-sm-12 col-lg-12">
									{/* <select name="libraryId" value={s.libraryId} onChange={this.handleInputChange} className="form-control input-sm">
										{p.libraryList && p.libraryList.map((tz) => <option key={tz.id} value={tz.id}>{tz.name}</option>)}
									</select> */}
									<SelectInputRow
										id="libraryId"
										name="libraryId"
										className="libraryId"
										label={I('Default Knowledge base for agent')}
										option={formatValueToNameObj(p.libraryList, "id", "name")}
										value={s.libraryId}
										onSelect={this.handleChangeData}
									/>
								</div>
							</div>
						</div>
						<Feature tag="machine-learning.auto-answer">
							<div className="col-lg-12 col-sm-12">
								<div className="form-group-org">
									<div className="col-sm-12 col-lg-12">
										<SelectInputRow
											id="libraryForAI"
											name="libraryForAI"
											className="libraryId"
											label={I('Default Knowledge base for AI')}
											option={formatValueToNameObj(p.libraryList, "id", "name")}
											value={s.libraryForAI}
											onSelect={this.handleChangeData}
										/>
									</div>
								</div>
							</div>
						</Feature>
					</Feature>
					<Feature tag="external-experts">
						<div className="col-lg-6 col-sm-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("External experts answers by email")}:</label>
								<div className="col-sm-12 col-lg-12">
									<SwitchCheckbox
										active={s.externalExpertsByEmail}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.externalExpertsByEmail, "externalExpertsByEmail") }}
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("External experts from address")}:</label>
								<div className="col-sm-12 col-lg-12">
									<input name="externalExpertFrom" className="form-control input-sm" type="text" value={s.externalExpertFrom} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-sm-6">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Pre-filled external experts address")}:</label>
								<div className="col-sm-12 col-lg-12">
									<input name="preFilledExternalExpert" className="form-control input-sm" type="text" value={s.preFilledExternalExpert} onChange={this.handleInputChange} />
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-sm-12">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Note to external experts")}:</label>
								<div className="col-sm-12 col-lg-12">
									<Ckeditor
										id={"noteToExternalExperts"}
										ref={"noteToExternalExperts"}
										locationHash={window.location.hash}
										defaultFontFamily={s.features}
										defaultFontSize={s.features}
										spellLanguages={s.languages || []}
										defaultContent={s.noteToExternalExperts || ""}
										showResize={false}
										simpleToolbar={false}
										fileArchiveImages={s.archiveImages || []}
										onChange={this.handleCkeditorChange}
										onKeydown={this.handleKeyDown}
									/>
								</div>
							</div>
						</div>
					</Feature>
					<Feature tag="satisfaction-feedback">
						<div className="col-lg-12 satisfaction-feedback">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Delay for sending Satisfaction meter email")}:</label>
								<div className="col-sm-6 col-lg-6">
									<input type="number" min="0" className="form-control input-sm" value={s.feedbackSendOutDelay} onChange={this.handleFeedbackDelay} />
									{I("hours")}
								</div>
							</div>
						</div>
					</Feature>
					<Feature tag="satisfaction-feedback">
						<CFlag flag="2023-11-20.CEN-1038.blackout.period.after.sent.survey">
							<div className="col-lg-12 blackout-period-after-sent-survey">
								<div className="form-group-org">
									<label className="col-sm-12 col-lg-12">{I("Blackout period after sent survey")}:</label>
									<div className="col-sm-6 col-lg-6">
										<input type="number" min="0" className="form-control input-sm" value={s.blackoutPeriodAfterSurvey} onChange={this.handleBlackoutPeriodAfterSurvey} />
										{I("hours")}
									</div>
								</div>
							</div>
						</CFlag>
					</Feature>

					<Feature tag="postpone-done-date">
						<div className="col-lg-12">
							<div className="form-group-org">
								<label className="col-sm-12 col-lg-12">{I("Enable postponed errands")}:</label>
								<div className="col-lg-1">
									<SwitchCheckbox
										active={s.hasPostpone}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.hasPostpone, "hasPostpone") }}
									/>
								</div>
								<div className="col-lg-1">
									<Helper helper={I("Tick for allowing this area to be used for Postpone errands. Errands can then be postponed to this area and will be moved back to the original area when the due date is reached.")} />
								</div>
							</div>
						</div>
					</Feature>
					<Feature tag="expected-done-date">
						<div className="due-date">
							<div className="col-lg-12">
								<div className="form-group-org">
									{/* <label className="col-lg-12">{I("Due date")}:</label> */}
									<div className="col-lg-6">
										{/* <select name="dueDate_dueDateSelected" value={s.dueDate.dueDateSelected} onChange={this.handleSubInputChangeInt} className="form-control input-sm">
											{dueDate.map((a) => <option key={a.id} value={a.id}>{a.value}</option>)}
										</select> */}
										<SelectInputRow
											id="dueDate_dueDateSelected"
											name="dueDate_dueDateSelected"
											className="dueDate_dueDateSelected"
											label={I('Due date')}
											option={formatValueToNameObj(dueDate, "id", "value")}
											value={s.dueDate.dueDateSelected}
											onSelect={this.handleChangeSubData}
										/>
									</div>
								</div>
							</div>
							{s.dueDate.dueDateSelected == 1 && <div id="due_date">
								<div className="margin-collapse-row row" style={{ display: "block" }}>
									<div className="col-lg-6">
										<div className="form-group-org">
											<label className="col-sm-12 col-lg-12">{I("Due Date address")}:</label>
											<div className="col-sm-12 col-lg-12">
												<input name="dueDate_doneDateAddress" className="form-control input-sm" type="text" value={s.dueDate.doneDateAddress} onChange={this.handleSubInputChange} />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group-org">
											<label className="col-sm-12 col-lg-12">{I("Due date reminder")}:</label>
											<div className="col-lg-2">
												<SwitchCheckbox
													active={s.dueDate.hasDoneDateNotification}
													buttonClassName={""}
													id={"orgActiveSwitch"}
													name={"orgActive"}
													type="checkbox"
													onClick={() => { this.handleSubSwitchBoxCheck(s.dueDate.hasDoneDateNotification, "dueDate_hasDoneDateNotification") }}
												/>
											</div>
											<div className="col-lg-1">
												<Helper helper={I("Allows notification to be sent to agent owning the errand when it is 2 days before the errand's due date.")} />
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group-org">
										<label className="col-sm-12 col-lg-12">{I("Move to area when errand is due")}:</label>
										<div className="area-dropdown-v5">
											<SingleSelectDD
												id={"manual-errand-areas"}
												textNoItemSelected={I("Select area")}
												className={"popup-multi-select"}
												nested={{ key: 'Areas' }}
												data={s.areaList}
												idFields={{ id: "Id", name: "Name" }}
												selected={s.dueDateAreaId}
												onSelect={this.onSelectDueDateArea}
												isHeader={true}
												onToggle={this.handleToggleArea}
												show={this.state.showArea}
												selectNone={true}
												selectAll={false}
											/>
										</div>
										<div className="col-lg-1">
											<Helper helper={I("Moves errand to specified area 1 day before the errand's due date. If there is less than 24 hours until a day before due date, the errand will not be moved.")} />
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group-org">
										<label className="col-lg-3">{I("Due Date Note")}:</label>
										<div className="col-lg-1">
											<Helper helper={I("Enter the text that should be sent out in the notification to the customer stating when their errand will be handled.")
												+ "<br />"
												+ I("You can use the following templates:")
												+ "<ul>"
												+ "<li><b>{DUEDATE}</b><br />"
												+ I("Shows the date the agent chose for the errand.")
												+ "</li>"
												+ "</ul>"
											} />
										</div>
										<div className="col-lg-12">
											{<Ckeditor
												id={"dueDate_expectedDoneDateNote"}
												ref={"dueDate_expectedDoneDateNote"}
												locationHash={window.location.hash}
												defaultFontFamily={s.features}
												defaultFontSize={s.features}
												spellLanguages={s.languages || []}
												defaultContent={s.dueDate.expectedDoneDateNote}
												showResize={false}
												simpleToolbar={false}
												fileArchiveImages={s.archiveImages || []}
												onChange={this.handleSubCkeditorChange}
												onKeydown={this.handleKeyDown}
											/>}
										</div>

									</div>
								</div>
							</div>}
						</div>
					</Feature>
				</div>
			</Feature >
			<div className="row">
				<div className="col-lg-6">
					<div className="form-group-org">
						<label className="col-sm-12 col-lg-12">{I("Delete all errands in the area")}:</label>
						<div className="col-sm-12 col-lg-12">
							<SwitchCheckbox
								active={s.removeErrandFromArea}
								buttonClassName={""}
								id={"orgActiveSwitch"}
								name={"orgActive"}
								type="checkbox"
								onClick={() => { this.handleSwitchBoxCheck(s.removeErrandFromArea, "removeErrandFromArea") }}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="form-group-org">
						<label className="col-sm-12 col-lg-12">{I("Days until deleting")}:</label>
						<div className="col-lg-10">
							<input name="daysToKeepErrand" className="form-control input-sm" type="text" value={s.daysToKeepErrand} onChange={this.handleInputChange} />
						</div>
						<div className="col-lg-1">
							<Helper helper={I("Effective only if Delete all errands in the area is enabled. If set to 0, all incoming errands in the area will be deleted immediately. Errands forwarded to the area by an agent will be deleted once per day.")} />
						</div>
					</div>
				</div>
			</div>
			<PCI
				onSelect={this.handlePCIAutoDeleteAttachments}
				options={reoccurActions}
				value={this.state.pci}
			/>
			<PCIHoursKeepAttachment
				onChange={this.handleChangePCIHoursKeepAttachment}
				value={this.state.pci}
			/>
			<div className="row">
				<div className="col-lg-12 agent-list-dropdown">
					<div className="form-group-org">
						<label className="col-xs-12 col-sm-12 col-lg-12">{I("Agent groups")}:</label>
						<div className="col-lg-5" style={{ paddingRight: "0px" }}>
							<DropDown
								id="all"
								name={I("All")}
								type={"list-multiselect"}
								items={s.agentGroupListing}
								selectedItems={this.selectedAvailableGroups}
								align="left"
								fields={{ id: "id", name: "name" }}
								onChange={this.onSelectAvailable}
								activeWhen="Active"
								multiSelect={true}
								wantSelectAll={true}
								wantSelectNone={true}
							/>
						</div>
						<div className="col-lg-2 textAlignCenter btn-group-vertical" style={{ padding: "5px 20px" }}>
							<span><a className="btn btn-default text-center" onClick={this.handleAddGroups}>{">"}</a></span>
							<p />
							<span><a className="btn btn-default text-center" onClick={this.handleRemoveGroups}>{"<"}</a></span>
						</div>
						<div className="col-lg-5" style={{ paddingLeft: "0px" }}>
							<DropDown
								id="members"
								name={I("Members")}
								type={"list-multiselect"}
								items={s.agentGroupSelected}
								selectedItems={this.selectedAddedMembers}
								align="left"
								fields={{ id: "id", name: "name" }}
								onChange={this.onSelectGroup}
								activeWhen="Active"
								multiSelect={true}
								wantSelectAll={true}
								wantSelectNone={true}
							/>
						</div>
					</div>
					<div className="form-group-org">
						<div className="margin-collapse-row row" style={{ display: "block" }}>
							<label className="col-lg-4">{I("Server emails")}:</label>
							<div className="col-lg-1">
								<Helper helper={I("Add aliases associated with the area's email address. Addresses added here will not be sent to when using \"Reply to all\". When opening an errand, the addresses here will be presented in the same priority order as in this list.")} />
							</div>
						</div>
						<div className="margin-collapse-row row" style={{ display: "block" }}>
							<div className="col-lg-5" style={{ paddingRight: "5px"}}>
								<input name="newServerEmail" className="form-control input-sm" type="text" value={s.newServerEmail} onChange={this.handleInputChange} />
								<DropDown
									id="serverEmailList"
									name={I("Emails")}
									type={"list-multiselect"}
									items={this.state.emailAddressList}
									selectedItems={this.selectedEmailServers}
									align="left"
									fields={{ id: "id", name: "name" }}
									onChange={this.onSelectEmailServer}
									activeWhen="true"
									multiSelect={false}
									singleSelect={true}
									sort={true}
									customSort={this.sortServerEmail}
								/>
							</div>
							<div className="col-lg-2 nopadding textAlignCenter btn-group-vertical">
								<span><a className="btn btn-default text-center" onClick={this.handleAddServerEmail}><i className="icon-add"></i></a></span>
								<p />
								<span><a className="btn btn-default text-center" onClick={this.handleRemoveServerEmail}><i className="icon-trash"></i></a></span>
								<p />
								<span><a className="btn btn-default text-center" onClick={this.handleServerEmailUp}><i className="icon-chevron-up"></i></a></span>
								<p />
								<span><a className="btn btn-default text-center" onClick={this.handleServerEmailDown}><i className="icon-chevron-down"></i></a></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Feature tag="data-protection.anonymize">
				<div className="row">
					<div className="col-lg-6">
						<div className="form-group-org">
							<label className="col-lg-12 col-sm-12">{I("Exclude for Right to be forgotten")}:</label>
							<div className="col-sm-2 col-lg-2">
								<SwitchCheckbox
									active={s.excludeAnonymize}
									buttonClassName={""}
									id={"orgActiveSwitch"}
									name={"orgActive"}
									type="checkbox"
									onClick={() => { this.handleSwitchBoxCheck(s.excludeAnonymize, "excludeAnonymize") }}
								/>
							</div>
							<div className="col-lg-2 col-sm-2">
								<Helper helper={I("Enable this option to prevent errand from anonymize")} />
							</div>
						</div>
					</div>

					{!s.excludeAnonymize &&
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-lg-12 col-sm-12">{I("Days until anonymize")}:</label>
								<div className="col-lg-10">
									<input name="anonymizeDay" className="form-control input-sm" type="text" value={s.anonymizeDay} onChange={this.handleInputChange} />
								</div>
								<div className="col-lg-1">
									<Helper helper={I("Number of days before errands are anonymized. Put 0 for no anonymize")} />
								</div>
							</div>
						</div>
					}
					{!s.excludeAnonymize && s.anonymizeDay != 0 &&
						<div className="col-lg-6">
							<div className="form-group-org">
								<label className="col-lg-12 col-sm-12">{I("Anonymize contact details")}:</label>
								<div className="col-lg-10">
									<SwitchCheckbox
										active={s.anonymizeContact}
										buttonClassName={""}
										id={"orgActiveSwitch"}
										name={"orgActive"}
										type="checkbox"
										onClick={() => { this.handleSwitchBoxCheck(s.anonymizeContact, "anonymizeContact") }}
									/>
								</div>
								<div className="col-lg-1">
									<Helper helper={I("Enable to anonymize contact details during schedule anonymize process")} />
								</div>
							</div>
						</div>
					}
				</div>
			</Feature>
			<Feature tag="openai-rewrite-answer">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("Custom AI Instructions")}:</label>
							<div className="col-sm-11 col-lg-11">
								<textarea
									name="customAIInstructions"
									className="form-control input-sm"
									placeholder={I("E.g: Rephrase my text to spellcheck and keep it short tidy.")}
									value={s.customAIInstructions}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="col-lg-1 col-sm-1">
								<Helper helper={I("What would you like AI to know about you to provide better responses ? Use placeholder [ANSWER] to be replaced with answer in errand handling.")} />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("How AI should respond ?")}</label>
							<div className="col-sm-11 col-lg-11">
								<textarea
									name="customAIResponse"
									className="form-control input-sm"
									placeholder={I("E.g: Write like a customer success manager in a customer service center.")}
									value={s.customAIResponse}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="col-lg-1 col-sm-1">
								<Helper helper={I("How would you like AI to respond? Use placeholder [ANSWER] to be replaced with answer in errand handling.")} />
							</div>
						</div>
					</div>
				</div>
			</Feature>
			<Feature tag="machine-learning.auto-answer">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("Enable auto answer")}:</label>
							<div className="col-lg-1">
								<SwitchCheckbox
									active={s.autoAnswer}
									buttonClassName={""}
									id={"orgActiveSwitch"}
									name={"orgActive"}
									type="checkbox"
									onClick={() => { this.handleSwitchBoxCheck(s.autoAnswer, "autoAnswer") }}
								/>
							</div>
							<div className="col-lg-1">
								<Helper helper={I("Enable this option to use generative AI to automatic answer errand in this area")} />
							</div>
						</div>
					</div>
				</div>
				{s.autoAnswer &&
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("The role of generative AI")}:</label>
							<div className="col-sm-11 col-lg-11">
								<textarea
									name="systemPromptText"
									className="form-control input-sm"
									placeholder={I("E.g: You are a helpful AI assistant in customer service, providing support for products and services.")}
									value={s.systemPromptText}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="col-lg-1 col-sm-1">
								<Helper helper={I("What would you like AI to know about you to provide better responses?")} />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="form-group-org">
							<label className="col-sm-12 col-lg-12">{I("How should generative AI respond")}</label>
							<div className="col-sm-11 col-lg-11">
								<textarea
									name="instructPromptText"
									className="form-control input-sm"
									placeholder={I("E.g: Based on the following context: {context}, please answer the question: {question}.")}
									value={s.instructPromptText}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="col-lg-1 col-sm-1">
								<Helper helper={I("How would you like AI to respond? Use the placeholder {context} for the retrieved content and {question} for the question to be asked.")} />
							</div>
						</div>
					</div>
				</div>
				}
			</Feature>
			<div className="form-group editPageActionButton">
				<div className="row">
					<div className="col-lg-12 textAlignRight">
						<CancelButton onClick={this.props.cancel} />
						<SaveButton
							key="btn-save-admin"
							onClick={() => { this.saveArea() }}
							text={this.props.saving ? BTN_TXT_SAVING : BTN_TXT_SAVE}
							disabled={this.props.saving}
						/>
					</div>
				</div>
			</div>
		</div >);
	}
	saveButton = () => {
		if (!this.props.saving) {
			return <button className="btn btn-primary btn-sm" type="button" onClick={this.saveArea}>{BTN_TXT_SAVE}</button>
		}
		return <button className="btn btn-primary btn-sm" type="button">{BTN_TXT_SAVING}</button>
	}
}
